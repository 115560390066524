import styled from "styled-components";

const StyledHome = styled.div`
    background-image: ${({ theme }) => `url(${theme.form_back})`};
    background-position: center;
    background-size: auto;
    background-repeat: repeat;
    width: 100%;
    height: 100vh;
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 575px) {
        background-size: 80% auto, cover;
    }
`;
const StyledHomeContent = styled.div`
    text-align: center;
    padding: 0 16px;
    max-width: 880px;
`;

const StyledText2 = styled.div`
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 50px;
    color: ${({ theme }) => theme.text};
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: "Clash Display", sans-serif;

    @media (max-width: 575px) {
        font-size: 38px;
        line-height: 40px;
        margin-bottom: 30px;
    }
`;

export default function Home() {
    return (
        <StyledHome>
            <StyledHomeContent>
                <StyledText2>Contract Listener Homepage</StyledText2>
            </StyledHomeContent>
        </StyledHome>
    );
}
