import { Switch, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Home from "./pages/Home";
import ChangePass from "./pages/ChangePass";
import ProtectedRoutes from "./ProtectedRoutes";
import Navbar from "./components/Navbar";
import "./App.css";

function App() {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/login" component={Login} />
        {/* <Route path="/signup" component={Register} /> */}
        <ProtectedRoutes path="/dashboard" component={Dashboard} />
        <ProtectedRoutes path="/change-pass" component={ChangePass} />
      </Switch>
    </>
  );
}

export default App;
