import Navbar from "react-bootstrap/Navbar";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { CustomButton } from "./Button";
import Cookies from "universal-cookie";
const cookies = new Cookies();



const StyledNavbarLogo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        font-family: "clash display";
        font-size: 24px;
        font-weight: 500;
    }
`;

function NavBar() {
    const token = cookies.get("TOKEN");

    const logout = () => {
        // destroy the cookie
        cookies.remove("TOKEN", { path: "/" });
        // redirect user to the landing page
        window.location.href = "/";
    }

    return (
        <Navbar
            collapseOnSelect
            expand="sm"
            bg="transparent"
            variant="light"
            className="px-3 py-3 justify-content-between position-absolute w-100"
        >
            <Navbar.Brand as={Link} to="/dashboard" tabIndex={1} rel="opener">
                <StyledNavbarLogo>
                    <h3>Contract Listener</h3>
                </StyledNavbarLogo>
            </Navbar.Brand>
            {token ? (
                <div className="d-flex justify-content-end align-items-center login-buttons">
                    <CustomButton
                        type="outline"
                        text="Change Password"
                        className="mx-2"
                        href="/change-pass"
                    />
                    <CustomButton
                        type="normal"
                        text="Log Out"
                        onClick={() => {
                            logout();
                        }}
                    />


                </div>
            ) : (
                <div className="d-flex justify-content-end align-items-center login-buttons">
                    {/* <CustomButton
                        type="normal"
                        text="Sign Up"
                        className="mx-2"
                        href="/signup"
                    /> */}
                    <CustomButton
                        type="outline"
                        text="Login"
                        className=""
                        href="/login"
                    />
                </div>
            )}
        </Navbar>
    );
}

export default NavBar;
