import styled from "styled-components";


const StyledButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    height: 52px;
    font-weight: 400;
    font-size: 16px;
    line-height: 180.5%;
    background: #ffffff;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    color: black;
    cursor: pointer;
    white-space: nowrap;
    max-width: 230px;
    text-decoration: none !important;
    color: #111928;
    font-family: "Clash Display", sans-serif;

    &.btn-gradient {
        background: #34A853;
        color: #101e21;
        width: 100%;
        max-width: initial;
    }

    &.btn-outline {
        outline: solid 1px #17272B;
        background: transparent;
        color: #17272B;
    }

    &.btn-undefined,
    &.btn-arrow {
        color: #111928;
    }
    &.btn-normal {
        background: #17272B;
        color: #E4ECEC;
    }


   

    &:hover,
    &:active {
        font-weight: 700;
    }

    @media (max-width: 575px) {
        padding: 0 20px;

        &.btn-border-bottom {
            padding: 0;
        }
    }

    @media (max-width: 450px) {
        padding: 0 10px;
        font-size: 12px;
    }
`;

export const CustomButton = (props) => {
    return (
        <StyledButton
            className={`btn-${props.type} ${props.className}`}
            href={props.href ? props.href : "#"}
            onClick={props.onClick}
            target={props.target ? props.target : ""}
        >
            {props.text}
        </StyledButton>
    );
};
