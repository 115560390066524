import styled from "styled-components";

export const StyledFormFont1 = styled.p`
    font-size: 40px;
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 25px;
`;

export const StyledFormFont2 = styled.p`
    font-size: 18px;
    font-weight: 500;
    line-height: 150%;
`;

export const StyledFormBack = styled.div`
    width: 100%;
    height: 100vh;
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledForm = styled.div`
    background: #F1FAFA;
    color: #17272B;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
        0px 1px 2px -1px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    width: 600px;
    padding: 40px 60px;
`;
