import { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TablePagination,
  TableRow,
  Button,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  TextField,
} from "@mui/material";
import useRefresh from "hooks/useRefresh";

const shortenString = (str: string, first: number, last: number): string => {
  return [String(str).slice(0, first), String(str).slice(-last)].join("...");
};

const cookies = new Cookies();
const token = cookies.get("TOKEN");

function Dashboard() {
  const { slowRefresh } = useRefresh();
  const [items, setItems] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [search, setSearch] = useState("");
  const [tokenOnly, setTokenOnly] = useState("true");

  const fetchData = async (
    offset: number,
    limit: number,
    order: string,
    orderBy: string,
    search: string,
    tokenOnly: string
  ) => {
    let additionalQuery = "";
    additionalQuery = `&search=${search}`;
    additionalQuery += `&tokenOnly=${tokenOnly}`;
    await fetch(
      `${process.env.REACT_APP_API}/list?offset=${offset}&limit=${limit}&order=${order}&orderBy=${orderBy}${additionalQuery}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setTotalCount(data.totalCount);
        setItems(data.data);
      });
  };

  useEffect(() => {
    fetchData(page * rowsPerPage, rowsPerPage, orderBy, order, search, tokenOnly);
  }, [orderBy, order, page, rowsPerPage, search, tokenOnly, slowRefresh]);

  const onPageChange = (event: any, value: number) => {
    setPage(value);
  };

  const onRowsPerPageChange = (event: any) => {
    setRowsPerPage(event.target.value);
  };

  const handlePin = async (deployer: string, pin: boolean) => {
    await fetch(`${process.env.REACT_APP_API}/pin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ deployer, pin: !pin }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          fetchData(
            page * rowsPerPage,
            rowsPerPage,
            orderBy,
            order,
            search,
            tokenOnly
          );
        }
      });
  };

  const handleVerify = async (deployer: string) => {
    await fetch(`${process.env.REACT_APP_API}/verify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ deployer }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          fetchData(
            page * rowsPerPage,
            rowsPerPage,
            orderBy,
            order,
            search,
            tokenOnly
          );
        }
      });
  };

  const handleDelete = async (deployer: string) => {
    await fetch(`${process.env.REACT_APP_API}/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ deployer }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          fetchData(
            page * rowsPerPage,
            rowsPerPage,
            orderBy,
            order,
            search,
            tokenOnly
          );
        }
      });
  };

  const onSearchChange = (e: any) => {
    setPage(0);
    setSearch(e.target.value);
  };

  const onTokenOnlyChange = (e: any) => {
    setPage(0);
    setTokenOnly(e.target.value);
  };

  return (
    <div className="p-[50px] text-center">
      <h1 className="text-2xl font-bold">Contract Listener Dashboard</h1>

      <div className="max-w-[1280px] mt-[50px] mx-auto">
        <div className="flex gap-5">
          <div className="flex gap-3">
            <TextField
              id="outlined-basic"
              label="Search"
              variant="outlined"
              className="w-[400px]"
              value={search}
              onChange={onSearchChange}
            />
          </div>
          {/* <div className="flex gap-3">
            <FormControl className="w-[120px]">
              <InputLabel id="demo-simple-select-label">Deployed</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={tokenOnly}
                label="Deployed"
                onChange={onTokenOnlyChange}
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"true"}>Token Only</MenuItem>
              </Select>
            </FormControl>
          </div> */}
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Pin</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "createdAt"}
                  direction={order}
                  onClick={() => {
                    setOrderBy("createdAt");
                    setOrder((state) => (state === "desc" ? "asc" : "desc"));
                  }}
                >
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell>Deployer</TableCell>
              <TableCell>Tokens</TableCell>
              <TableCell>Non-tokens</TableCell>
              <TableCell>Fund Wallet</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "portfolio"}
                  direction={order}
                  onClick={() => {
                    setOrderBy("portfolio");
                    setOrder((state) => (state === "desc" ? "asc" : "desc"));
                  }}
                >
                  Balance
                </TableSortLabel>
              </TableCell>
              <TableCell>Verification</TableCell>
              <TableCell>Remove</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item: any, index: number) => {
              return (
                <TableRow hover key={`row_${index}`}>
                  <TableCell
                    className="cursor-pointer"
                    onClick={() => handlePin(item.deployer, item.pin)}
                  >
                    {item.pin ? "✓" : "🔘"}
                  </TableCell>
                  <TableCell>
                    {new Date(item.createdAt).toLocaleString("en", {
                      month: "numeric",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </TableCell>
                  <TableCell>
                    <a
                      className="text-[#6aa2ec] underline"
                      href={`https://etherscan.io/address/${item.deployer}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {shortenString(item.deployer, 6, 4)}
                    </a>
                  </TableCell>
                  <TableCell>
                    {item.tokens.map((it: any) => (
                      <div className="flex">
                        <a
                          className="text-[#6aa2ec] underline"
                          href={`https://etherscan.io/address/${it.contract}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {it.symbol} ({shortenString(it.contract, 6, 4)})
                        </a>{" "}
                        {it.verified ? "🟢" : ""}
                      </div>
                    ))}
                  </TableCell>
                  <TableCell>
                    {item.noTokens.map((it: any) => (
                      <div className="flex">
                        <a
                          className="text-[#6aa2ec] underline"
                          href={`https://etherscan.io/address/${it.contract}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {shortenString(it.contract, 6, 4)}
                        </a>{" "}
                        {it.verified ? "🟢" : ""}
                      </div>
                    ))}
                  </TableCell>
                  <TableCell>
                    <a
                      className="text-[#6aa2ec] underline"
                      href={`https://etherscan.io/address/${item.fundWallet}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.fundWalletName ||
                        shortenString(item.fundWallet, 6, 4)}
                    </a>
                  </TableCell>
                  <TableCell>
                    $
                    {new Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 0,
                    }).format(item.portfolio)}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => handleVerify(item.deployer)}>
                      Verify
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => handleDelete(item.deployer)}>
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          className="mt-3"
          count={totalCount}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />
      </div>
    </div>
  );
}

export default Dashboard;
